import React from 'react';
import { bool } from 'prop-types';
import loadable from '@loadable/component';

import {
  ALL_CARDS_ROUTE,
  CREATE_ROUTE,
} from 'constants/navigation';
import {
  LOYALTY_CARD_NAV,
  ADD_NAV,
} from 'constants/font-awesome';

import { Skeleton } from '@material-ui/lab';

import useStyles from './styles';

const LazyBanner = loadable(() => import('components/banners/in-app-banners/one'));
const Page = loadable(() => import('components/page'));
const ApplicationFooter = loadable(() => import('components/footer/application-footer'));
const HomeLoggedInView = loadable(() => import('views/home/logged-in'));

const Typography = loadable(() => import('@material-ui/core/Typography'));
const Hidden = loadable(() => import('@material-ui/core/Hidden'));

const HomeSuspendedLoggedInPage = ({
  canAdd = false,
  hasPass = true,
  hasSub = false,
}) => {
  const styles = useStyles();

  return (
        <Page
          title="Home page"
          description="Welcome to Total Loyalty, Digital loyalty made easy for everyone"
          noAuth>
          <LazyBanner falback={<Skeleton
            animation="wave"
            component="div"
            height={300}
            className={styles.landingSkeletonBanner}
            variant="rect"
            />}/>
            {hasPass ? (
              <>
              <Typography variant="h2" component="h1" className={styles.heading} align="center">
                Welcome back
              </Typography>
              <Typography variant="h4" component="p" className={styles.headingMessage} align="center">
                Manage your customer loyalty
              </Typography>
              </>
            ) : (
              <>
              <Hidden xsDown>
                <Typography variant="h2" component="h1" className={styles.heading} align="center">
                  Welcome to Total Loyalty
                </Typography>
              </Hidden>
              <Hidden smUp>
                <Typography variant="h2" component="h1" className={styles.heading} align="center">
                  Welcome to<br/>Total Loyalty
                </Typography>
              </Hidden>
              <Typography variant="h4" component="p" className={styles.headingMessage} align="center">
                Build your customer loyalty
              </Typography>
            </>
            )}

            <HomeLoggedInView canAdd={canAdd} hasPass={hasPass}/>
            <ApplicationFooter
              navItems={canAdd ? [
                { route: ALL_CARDS_ROUTE, icon: LOYALTY_CARD_NAV, message: 'Cards' },
                { route: CREATE_ROUTE, icon: ADD_NAV, message: 'Add' },
              ] : [
                { route: ALL_CARDS_ROUTE, icon: LOYALTY_CARD_NAV, message: 'Cards' },
              ]}
              currentItem="Home"
              hasSubscription={hasSub}
              />
        </Page>
  );
};
HomeSuspendedLoggedInPage.propTypes = { canAdd: bool, hasPass: bool, hasSub: bool };

export default HomeSuspendedLoggedInPage;
