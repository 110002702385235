import theme from 'theme';
import { makeStyles } from '@material-ui/core/styles';

import { baseStyles } from 'suspended-page/styles';

export default makeStyles({
  ...baseStyles,
  landingSkeletonBanner: {
    marginLeft: theme.spacing(-1),
    marginRight: theme.spacing(-1),
    marginTop: theme.spacing(-1.5),
  },
});
